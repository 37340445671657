import React, { useEffect, useState } from 'react';

export default function Countdown(props) {
  const [countdownDate, setCountdownDate] = useState(
    new Date(props.date).getTime(),
  );
  const [state, setState] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const tick = setInterval(() => setNewTime(), 1000);

    return () => clearInterval(tick);
  }, []);

  const setNewTime = () => {
    if (countdownDate) {
      const currentTime = new Date().getTime();

      const distanceToDate = countdownDate - currentTime;

      let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
      let hours = Math.floor(
        (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      );
      let minutes = Math.floor(
        (distanceToDate % (1000 * 60 * 60)) / (1000 * 60),
      );
      let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);

      const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];

      days = `${days}`;

      if (numbersToAddZeroTo.includes(hours)) {
        hours = `0${hours}`;
      } else if (numbersToAddZeroTo.includes(minutes)) {
        minutes = `0${minutes}`;
      } else if (numbersToAddZeroTo.includes(seconds)) {
        seconds = `0${seconds}`;
      }

      setState({
        days: days < 0 ? 0 : days,
        hours: hours < 0 ? 0 : hours,
        minutes: minutes < 0 ? 0 : minutes,
        seconds: seconds < 0 ? 0 : seconds,
      });
    }
  };

  return (
    <span>
      {state.days != 0 && state.days + 'D:'}
      {state.hours != 0 && state.hours + 'H:'}
      {state.minutes != 0 && state.minutes + 'M:'}
      {state.seconds != 0 && state.seconds + 'S'}
    </span>
  );
}
