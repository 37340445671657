import Button from '@components/Button/index';
// import AwesomeConfetti from '@components/Confetti/index';
import Countdown from '@components/Countdown/index';
import useResizer from '@components/extra/useResizer';
import FooterImage from '@components/FooterImage/index';
import Header from '@components/Header/index';
import Loader from '@components/Loader/index';
import MyTypeform from '@components/MyTypeform/index';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { Link, navigate } from 'gatsby';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ResponsiveEmbed from 'react-responsive-embed';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../../context/GlobalContextProvider';
import db from '../../data/db.json';
import testimonial from '../../images/Testimonial.png';
import * as styles from './Giveaway.module.scss';
import { doLoginToBackend } from '@src/api/index';
import { useAuth } from '@src/firebase/useAuth';
import toast from 'react-hot-toast';
import { StaticImage } from 'gatsby-plugin-image';
import FooterV2 from '@components/FooterV2/index';

export default function Giveaway(props) {
  const ref = useRef();
  let timeout_ref = null;
  const confettiRef = useRef();
  const isMobile = useResizer();
  const auth = useAuth();
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const [affiliate, setAffiliate] = useState('');
  const [affiliateData, setAffiliateData] = useState(null);
  const [packData, setPackData] = useState(null);

  const [expired, setExpired] = useState(false);
  const [typeformSubmitted, setTypeformSubmitted] = useState(false);

  const [valid, setValid] = useState(true);
  const [myParam, setMyParam] = useState('');

  const [copySuccess, setCopySuccess] = useState('');
  const toastList = new Set();
  const MAX_TOAST = 1;

  const style = {
    position: 'fixed',
    width: '100%',
    // top: "-100px",
    // height: "100%",
    // zIndex: 1,
  };
  function onClickDefault() {
    confettiRef();
  }

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };
  function sanitizeHelper(str) {
    return str.replace(/\s+/g, '-').toLowerCase();
  }
  function notify(code) {
    if (toastList.size < MAX_TOAST) {
      const id = toast.success(`Promo Code ${code} copied!`, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: 'success-toast',
        onClose: () => toastList.delete(id),
      });

      toastList.add(id);
    }
  }
  const config = {
    angle: 270,
    spread: 360,
    startVelocity: 40,
    elementCount: 140,
    dragFriction: 0.12,
    duration: 5000,
    stagger: 3,
    width: '10px',
    height: '10px',
    perspective: '500px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
  };

  useEffect(() => {
    let temp = false;

    const urlParams = new URLSearchParams(window.location.search);

    const myParam = urlParams.get('affiliate');
    const myParam2 = urlParams.get('a');

    db['strapi_metadata']['affiliates'].forEach((y) => {
      if (y.UID === myParam || y.UID === myParam2) {
        temp = true;
        setAffiliate(y.UID);
        return;
      }
    });
    if ((myParam == null && myParam2 == null) || temp === false) {
      navigate('/');
    } else {
      if (myParam !== null) setMyParam(myParam);
      else if (myParam2 !== null) setMyParam(myParam2);

      let temp = window.location.search;
      let utm = '';
      if (temp.includes('?a=')) {
        let url_string = window.location.href;
        let url = new URL(url_string);
        let c = url.searchParams.get('a');

        if (c !== null) {
          utm = 'utm_source=' + c + '&utm_medium=affiliate-giveaway';
          dispatch({
            type: 'SET_UTM',
            payload: utm,
          });
        }
      } else if (temp.includes('?affiliate=')) {
        let url_string = window.location.href;
        let url = new URL(url_string);
        let c = url.searchParams.get('affiliate');
        if (c !== null) {
          utm = 'utm_source=' + c + '&utm_medium=affiliate-giveaway';
          dispatch({
            type: 'SET_UTM',
            payload: utm,
          });
        }
      }
    }
    let affiliate_data = db['strapi_metadata']['affiliates'].find(
      (ele) => ele.UID == myParam2,
    );
    let pack_data = db['strapi_metadata']['packs'].find(
      (ele) => ele.id == affiliate_data?.pack?.id,
    );
    setAffiliateData(affiliate_data);
    setPackData(pack_data);

    timeout_ref = setInterval(
      () => checkTime(affiliate_data['OfferLastDate']),
      1000,
    );
  }, []);

  function checkTime(time) {
    if (new Date(time) < new Date()) {
      setExpired(true);
      clearInterval(timeout_ref);
    }
  }
  // let affiliateData = db["strapi_metadata"]["affiliates"].find(
  //   (ele) => ele.UID == affiliate
  // );

  const handlePostFirebaseLogin = async (idToken, cb) => {
    let response = await doLoginToBackend(idToken);
    if (response) {
      cb();
    } else {
      toast.error(
        'Authentication failed due to poor network connection. Please refresh your browser and try again.',
        { duration: 8000, style: { fontSize: '14px' }, id: 'auth' },
      );
    }
  };
  async function handleLogin(e, url) {
    e.preventDefault();
    if (auth?.user === null) {
      return;
    }
    if (auth?.user) {
      const idToken = await auth.getIdToken();
      handlePostFirebaseLogin(idToken, () => {
        window.open(url, '_blank');
      });
      return;
    }
    auth.signin().then(async (res) => {
      const idToken = res.idToken;

      handlePostFirebaseLogin(idToken, () => {
        window.open(url, '_blank');
      });
      return;
    });
  }

  return valid && affiliateData && packData ? (
    <>
      <GatsbySeo
        title={`Crio Giveaway | ${affiliateData['Name']}`}
        noindex={true}
      />

      <div className="fixed-header">
        {expired ? (
          <div
            className={`${styles.expireBanner} py-2 text-center text-white ${
              expired ? 'bg-gray-600' : 'bg-crio-green-600 '
            }`}
          >
            <div className="flex w-screen items-center  justify-center whitespace-nowrap text-center	">
              <FontAwesomeIcon
                icon={['fas', 'exclamation-circle']}
                className="mr-2 text-lg text-white"
              />{' '}
              Offer expired on{' '}
              <span className="ml-1 font-bold">
                {format(
                  new Date(affiliateData['OfferLastDate']),
                  'dd MMM yyyy',
                )}{' '}
              </span>
            </div>
          </div>
        ) : (
          <div
            className={`${
              styles.expireBanner
            } whitespace-nowrap py-3 text-center text-sm  text-white ${
              expired ? 'bg-red-700' : 'bg-crio-green-600 '
            }`}
          >
            <div className="flex items-center justify-center 	">
              <FontAwesomeIcon
                icon={['fad', 'gift']}
                className="mr-2 text-lg text-white"
              />{' '}
              Offer expires on{' '}
              <span className="ml-1 font-bold">
                {format(
                  new Date(affiliateData['OfferLastDate']),
                  'dd MMM yyyy',
                )}{' '}
                |
              </span>
              <span className="ml-1 font-bold">
                {' '}
                <Countdown
                  date={affiliateData['OfferLastDate']}
                ></Countdown>{' '}
                left
              </span>
            </div>
          </div>
        )}
        <Header
          scrolled={true}
          disable={['Programs', 'For Business', 'Projects']}
          disableCTA={true}
        ></Header>{' '}
      </div>

      {/* {isMobile && typeformSubmitted && (
        <AwesomeConfetti
          particleCount={5}
          angle={90}
          spread={55}
          startVelocity={50}
          colors={['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a']}
        />
      )}

      {!isMobile && typeformSubmitted && (
        <>
          <AwesomeConfetti
            particleCount={40}
            angle={60}
            spread={55}
            startVelocity={70}
            origin={{ x: 0 }}
            colors={['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a']}
          />
          <AwesomeConfetti
            particleCount={40}
            startVelocity={70}
            angle={120}
            spread={55}
            origin={{ x: 1 }}
            colors={['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a']}
          />
        </>
      )} */}

      <div style={{ zIndex: '2 !important' }}>
        <div className={`bg-crio-green-200 ${styles.hero} pt-10 pb-10`}>
          <div className="container pt-28 text-white md:pt-20">
            <div className="row flex flex-col items-center  justify-center text-center">
              <h2 className="col-lg-12     items-center justify-center">
                <span className="font-normal">
                  {affiliateData['Name']} brings you
                </span>
              </h2>{' '}
              <h1 className=" ml-2 mb-5"> {packData['Title']}</h1>
              <p className=" mb-8 font-light md:w-6/12 ">
                {packData['Description']}
              </p>
            </div>{' '}
            <div className="flex items-center justify-center text-center">
              {packData['Skills'].map((skill, idx) => (
                <div
                  key={idx}
                  className="mx-2 rounded-2xl bg-white px-3 py-1 text-sm text-gray-700"
                >
                  {skill['Name']}
                </div>
              ))}
            </div>
          </div>
        </div>

        <section className="pt-10 pb-24" style={{ background: '#EAE8DF' }}>
          {' '}
          <div className="container   flex flex-col items-center justify-center text-center">
            {' '}
            {expired ? (
              <h4 className="mb-10 font-semibold">
                We regret to inform that this offer is no more available.
              </h4>
            ) : typeformSubmitted ? (
              <div className="mb-20 flex flex-col items-center justify-center ">
                <h4 className="mb-5 font-semibold">
                  Yay! We are excited to have you enrolled.
                </h4>
                <div onClick={(e) => handleLogin(e, '/learn')} target="_blank">
                  {' '}
                  <Button className="bg-crio-yellow-500">
                    Get Started Now
                  </Button>
                </div>
              </div>
            ) : (
              <div className="row flex w-full items-center justify-center ">
                <div className="col-12 col-lg-6 ">
                  <div
                    id="giveaway-typeform"
                    className=" overflow-hidden"
                    style={{ width: '100%', height: '300px' }}
                  >
                    <MyTypeform
                      url="FDBB0wrJ"
                      params={{
                        programId: affiliateData.pack['ProgramID'],
                      }}
                      height={'300px'}
                      hideWelcomeScreen={true}
                      onSubmit={(values) => {
                        setTypeformSubmitted(true);
                      }}
                      hideScrollbars
                      hideHeaders
                      hideFooter
                    ></MyTypeform>
                  </div>
                </div>
              </div>
            )}{' '}
            <div className="row flex w-full  items-center justify-center text-center ">
              <h4 className="mb-10 font-semibold">
                {expired
                  ? 'What registered users learnt here by doing'
                  : 'What you’ll learn here by doing'}
              </h4>
            </div>
            <div className="md:ml-20 md:mr-20">
              <div className="row flex justify-center">
                {packData.pack_items.map((pack_item, idx) => {
                  return (
                    <div
                      className="col-12 col-lg-6 relative mb-6 flex justify-center font-bold text-white"
                      key={idx}
                    >
                      <div className="relative h-[200px] overflow-hidden rounded-lg">
                        <h5 className="absolute  top-1/2  left-0 right-0 z-10 z-10 -translate-y-1/2  transform ">
                          {pack_item['Title']}
                        </h5>
                        <StaticImage
                          loading="lazy"
                          src="../../images/web-bg.png"
                          placeholder="blurred"
                          alt="Background"
                        />
                      </div>
                      {/* <StyledBackgroundSection
                        image="web-bg.png"
                       
                      ></StyledBackgroundSection> */}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
        <section
          className=" pt-16 pb-16"
          style={{
            backgroundImage: `url(${testimonial})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div className="container   text-center">
            <h2 className=" mb-3 p-0" style={{ color: '#09736C' }}>
              Why Developers love Crio
            </h2>
            <p className="mb-16">
              {' '}
              Don’t just take our word. Hear the journey of developers with us.
              And, embark on yours!
            </p>
            <div className="iframeVideo mb-10  md:hidden">
              <iframe
                loading="lazy"
                src="https://www.youtube.com/embed/gQiXa9JI1Uw?rel=0&enablejsapi=1&origin=https%3A%2F%2Fcrio.do"
                frameBorder="0"
                allowFullScreen="allowFullScreen"
              />
            </div>
            <div className=" mb-10 hidden md:flex md:justify-center">
              <div
                className=" "
                style={{
                  width: '560px',
                  height: '315px',
                  // marginBottom: "-100px",
                }}
              >
                <span ref={ref}>
                  <ResponsiveEmbed
                    className="test"
                    src="https://www.youtube.com/embed/gQiXa9JI1Uw?rel=0&enablejsapi=1&origin=https%3A%2F%2Fcrio.do"
                    ratio="16:9"
                    allowFullScreen
                  />
                </span>
              </div>
            </div>
            <div className="mb-16 text-center">
              <h5 style={{ color: '#09736C' }} className="font-normal">
                Namrata Kumari
              </h5>
              <p>Cloud Software Developer, IBM Labs</p>
              <a
                href={'https://www.linkedin.com/in/namrata-kumari/'}
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={['fab', 'linkedin']}
                  style={{
                    color: '#000',
                    marginLeft: '8px',
                    cursor: 'pointer',
                    // marginTop: "-4px",
                    // fontSize: "30px",
                  }}
                  size="2x"
                />{' '}
              </a>
            </div>
            <div className="row">
              <div className="col-lg-6 mb-10 ">
                <a href="/testimonials" target="_blank">
                  <div className="testimonial-card-odd">
                    “It was a wonderful journey with a lot of practical
                    learning. With no experience in many of the tech stacks like
                    Spring Boot, MongoDB, etc I still managed to build a
                    scalable distributed backend system that is running live in
                    Google Cloud. All this was possible because of the wonderful
                    team at Crio who made us realise that "learning is doing".
                    The experience...”
                  </div>
                </a>
                <div className="testimonial-author-circle  mb-3">
                  {/* <Image
                    filename="Testimonial_Circle/Piyush.png"
                    style={{
                      width: '75px',
                      borderRadius: '50%',
                    }}
                  ></Image> */}
                  <StaticImage
                    loading="lazy"
                    src="../../images/Testimonial_Circle/Piyush.png"
                    placeholder="blurred"
                    alt="Industry Testimonial"
                    style={{
                      width: '75px',
                      borderRadius: '50%',
                    }}
                  />
                </div>
                <div className="flex flex-col items-center justify-center">
                  <h5 style={{ color: '#09736C' }} className="font-normal">
                    Piyush Pant
                  </h5>
                  <p>Backend Engineer, CRED </p>
                  <a
                    href={'https://www.linkedin.com/in/piyushpant17/'}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={['fab', 'linkedin']}
                      style={{
                        color: '#000',
                        marginLeft: '8px',
                        cursor: 'pointer',
                        // marginTop: "-4px",
                        // fontSize: "30px",
                      }}
                      size="2x"
                    />{' '}
                  </a>
                </div>
              </div>
              <div className="col-lg-6 mb-10">
                {' '}
                <a href="/testimonials" target="_blank">
                  <div className="testimonial-card-even">
                    “Crio Micro-Experiences are the best thing that has happened
                    to me. When I applied for this program I thought it might be
                    similar to the other programs in the market - watching
                    assigned videos and implementing things. But I was wrong.
                    The learning I got from Crio is incomparable. The modules
                    were designed in such a way that ...”
                  </div>
                </a>
                <div className="testimonial-author-circle  mb-3">
                  {/* <Image
                    filename="Testimonial_Circle/Roshan.png"
                    style={{
                      width: '75px',
                      borderRadius: '50%',
                    }}
                  ></Image> */}
                  <StaticImage
                    loading="lazy"
                    src="../../images/Testimonial_Circle/Roshan.png"
                    placeholder="blurred"
                    alt="Industry Testimonial"
                    style={{
                      width: '75px',
                      borderRadius: '50%',
                    }}
                  />
                </div>
                <div className="flex flex-col items-center justify-center">
                  <h5 style={{ color: '#09736C' }} className="font-normal">
                    Roshan Dawande
                  </h5>
                  <p>Software Engineer, Vogo </p>
                  <a
                    href={'https://www.linkedin.com/in/rdroshan/'}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={['fab', 'linkedin']}
                      style={{
                        color: '#000',
                        marginLeft: '8px',
                        cursor: 'pointer',
                        // marginTop: "-4px",
                        // fontSize: "30px",
                      }}
                      size="2x"
                    />{' '}
                  </a>
                </div>
              </div>
              <div className="col-lg-6 mb-3">
                {' '}
                <a href="/testimonials" target="_blank">
                  <div className="testimonial-card-even">
                    “My Crio journey was awesome and it feels great that I was a
                    part of it! Through the Micro-Experiences I have learnt a
                    bunch of industry relevant skills. There were several
                    modules where I was stuck for long, but the team at Crio
                    always kept me motivated and guided me in the right
                    direction. My major takeaways from the program are...”
                  </div>
                </a>
                <div className="testimonial-author-circle  mb-3">
                  <StaticImage
                    loading="lazy"
                    src="../../images/Testimonial_Circle/Aarshiya.png"
                    placeholder="blurred"
                    alt="Industry Testimonial"
                    style={{
                      width: '75px',
                      borderRadius: '50%',
                    }}
                  />
                  {/* <Image
                    filename="Testimonial_Circle/Aarshiya.png"
                    style={{
                      width: '75px',
                      borderRadius: '50%',
                    }}
                  ></Image> */}
                </div>
                <div className="flex flex-col items-center justify-center">
                  <h5 style={{ color: '#09736C' }} className="font-normal">
                    Aarshiya Khandelwal
                  </h5>
                  <p>Engineer, Western Digital </p>
                  <a
                    href={'https://www.linkedin.com/in/aarshiya-khandelwal/'}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={['fab', 'linkedin']}
                      style={{
                        color: '#000',
                        marginLeft: '8px',
                        cursor: 'pointer',
                        // marginTop: "-4px",
                        // fontSize: "30px",
                      }}
                      size="2x"
                    />{' '}
                  </a>
                </div>
              </div>
              <div className="col-lg-6 mb-3 ">
                <a href="/testimonials" target="_blank">
                  <div className="testimonial-card-odd">
                    “I have completed multiple bootcamps, tutorials, video
                    lectures and tried many types of learning methodologies that
                    are claimed to be the best, but Crio begs to differ. An
                    e-learning startup with a completely novel approach to
                    hand-on learning was the best learning experience I had
                    until now. I got to work on 4 different projects and a mini
                    project which covered...”
                  </div>
                </a>
                <div className="testimonial-author-circle  mb-3">
                  <StaticImage
                    loading="lazy"
                    src="../../images/Testimonial_Circle/Apoorv.png"
                    placeholder="blurred"
                    alt="Industry Testimonial"
                    style={{
                      width: '75px',
                      borderRadius: '50%',
                    }}
                  />
                  {/* <Image
                    filename="Testimonial_Circle/Apoorv.png"
                    style={{
                      width: '75px',
                      borderRadius: '50%',
                    }}
                  ></Image> */}
                </div>
                <div className="flex flex-col items-center justify-center">
                  <h5 style={{ color: '#09736C' }} className="font-normal">
                    Apoorv Goyal
                  </h5>
                  <p>Student Developer, Vellore Institute of Technology</p>
                  <a
                    href={'https://www.linkedin.com/in/apoorv-goyal/'}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={['fab', 'linkedin']}
                      style={{
                        color: '#000',
                        marginLeft: '8px',
                        cursor: 'pointer',
                        // marginTop: "-4px",
                        // fontSize: "30px",
                      }}
                      size="2x"
                    />{' '}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container flex  flex-col items-center justify-center pt-10 pb-20 text-center md:flex-row md:text-left">
            <h3 className="mr-10 mb-10 md:mb-0">
              Wish to know about more <br className="hidden md:block"></br>
              Programs offered in Crio.Do?
            </h3>
            <Link to="/#programs">
              <Button
                className="bg-crio-green-500 text-white"
                style={{ height: '50px' }}
              >
                View our Programs
              </Button>
            </Link>
          </div>
        </section>
      </div>
      <FooterImage
        style={{ background: state.unlocked ? 'transparent' : '#fff' }}
      ></FooterImage>
      {/* <Footer></Footer> */}
      <FooterV2 />
    </>
  ) : (
    <div
      className="flex  items-center justify-center"
      style={{ width: '100vw', height: '100vh' }}
    >
      <Loader></Loader>
    </div>
  );
}

// export default AboutUsPage;
